<template>
  <div class="edit-sale-addon-character-box">

    <div class="character-body" v-if="collapseStatus">
      <span class="character-name">
          {{selectedCharacter.character.name}}
      </span>

      <div class="checkbox-input">
        <label :for="`requied_${selectedCharacter.character.id}`">
          <input :id="`requied_${selectedCharacter.character.id}`"
                 type="checkbox"
                 v-model="selectedCharacter.required"/>
          {{ $t('sales.addons.labels.required') }}
        </label>

      </div>
      <custom-price-input class="mb-0 w-full"
                          :is-empty="true"
                          v-model="selectedCharacter.price"
                          :label="$t('sales.addons.labels.price')"/>
    </div>
  </div>
</template>

<script>
  import CustomPriceInput from "../../../../../components/customInput/customPriceInput";

  export default {
    name: 'editSaleAddonCharacter',
    components: {CustomPriceInput},
    model: {
      prop: 'selectedCharacter',
      event: 'input'
    },
    props: {
      selectedCharacter: {},
    },
    data() {
      return {
        collapseStatus: true
      }
    },
    computed: {
      salePriceValidation() {
        const price = parseInt(this.selectedCharacter.price.toString().replaceAll(',', ''))
        const salePrice = parseInt(this.selectedCharacter.sale_price.toString().replaceAll(',', ''))
        return salePrice < 0 || salePrice >= price
      }
    }
  }
</script>

<style lang="scss">

  .edit-sale-addon-character-box {
    display: flex;
    flex-direction: column;
    border: 1px solid #cecece;
    border-radius: .5rem;
    margin-bottom: 15px;

    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Old versions of Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none;
    /* Non-prefixed version, currently
                                     supported by Chrome, Edge, Opera and Firefox */

    .character-header {
      cursor: pointer;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      line-height: 40px;
      padding: 0 10px;

      .character-title {
        font-weight: 500;

        .attributes {
          display: flex;
          flex-direction: row;
          align-items: center;

          .select-attribute {
            width: max-content;
            height: 35px;
            line-height: 35px;
            margin-left: 10px;

            .selected::after {
              top: 6px;
              bottom: 0;
              margin: auto;
              border-color: #cecece transparent transparent transparent;
            }
          }
        }
      }

      .character-icons {
        display: flex;

        .delete-character-btn {
          display: flex;
          align-items: center;
          transition: all ease 0.2s;
          opacity: 0;
        }

        .character-collapse {
          width: 30px;
          display: flex;
          justify-content: space-around;
          align-items: center;
          transition: all .15s ease;

          &.active {
            transform: rotate(180deg);
          }
        }
      }
    }

    .character-body {
      padding: 0 10px 10px;
      display: flex;
      position: relative;

      .character-name {
        position: absolute;
        top: -12px;
        left: 7px;
        padding: 0 5px;
        background-color: #ffffff;
      }


      .checkbox-input {
        width: max-content;
        margin-top: 15px;
        margin-right: 15px;
        display: flex;
        align-items: center;

        label {
          display: flex;
          align-items: center;
          width: 100%;
          white-space: nowrap;

          &:hover {
            cursor: pointer;
          }

          input[type=checkbox] {
            height: 15px;
            width: 15px;
            margin-right: 10px;
          }
        }
      }
    }

    &:hover .character-header{
      .character-icons{
        .delete-character-btn {
          opacity: 1;
        }
      }
    }
  }

</style>
